import React from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { defaultLanguage } from '../../i18n/config';
import { BlogArticleHreflangs } from '../../interface/template';

interface Props {
  locale: string;
  hreflangs: BlogArticleHreflangs[];
  title: string;
  description: string;
  seoInfo: { image?: { url: string } };
  headerImage?: { url: string };
}

const IRONHACK_URL = process.env.IRONHACK_URL;

const generateHrefLang = (
  hrefLink: string,
  languageCode: string,
  index: number
): JSX.Element => (
  <link
    href={`${IRONHACK_URL}${hrefLink}`}
    hrefLang={languageCode}
    key={`hrefLang-${index}`}
    rel="alternate"
  />
);

const SEOBlog: React.SFC<any> = (props: Props) => {
  const { locale, hreflangs, title, description, seoInfo, headerImage } = props;
  const englishHrefLang = hreflangs.find(
    ({ lang }) => lang === defaultLanguage
  );
  const defaultHrefLang =
    englishHrefLang && generateHrefLang(englishHrefLang.url, 'X-Default', 0);

  const languages: Record<string, string> = {
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    de: 'de-DE',
    nl: 'nl-NL',
    pt: 'pt-PT',
    br: 'pt-BR',
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: languages[locale],
      }}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          name: 'facebook-domain-verification',
          content: '8783nkfgyn58vwde7prj16zimolgmn',
        },
        {
          name: 'og:site_name',
          content: 'Ironhack',
        },
        {
          name: 'og:image',
          content: _.get(seoInfo, 'image.url') || _.get(headerImage, 'url'),
        },
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:description',
          content: description,
        },
      ]}
      title={title}
    >
      {hreflangs.reduce(
        (
          links: JSX.Element[],
          hreflang: BlogArticleHreflangs
        ): JSX.Element[] => {
          links.push(
            generateHrefLang(
              hreflang.url,
              languages[hreflang.lang],
              links.length
            )
          );
          return links;
        },
        defaultHrefLang ? [defaultHrefLang] : []
      )}
      <meta
        content={
          process.env.NODE_ENV !== 'production'
            ? 'noindex, nofollow, noarchive, nosnippet, nocache'
            : 'index, follow'
        }
        name="robots"
      />
    </Helmet>
  );
};

export default withTranslation()(SEOBlog);
