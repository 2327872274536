import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import '../../lib/moment.ts';
import withI18next from '../../i18n/TemplateI18n';
import SEOBlog from '../../components/SeoBlog';
import Header from '../../components/BlogHeader';
import Body from '../../components/BlogBody';
import Layout from '../../components/atoms/layout';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import ContextualCta from '../../components/ContextualCta';
import Wrapper from './styles';
import Text5 from '../../components/atoms/body/text5';
import useUserLocation from '../../hooks/useUserLocation';
import Banner from '../../components/Banner';

const PressRelease: React.SFC<any> = (props) => {
  const { locale, hreflangs } = props.pageContext;
  const { headline, content, seoInformation, date } =
    props.pageContext.pressRelease;
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <SEOBlog locale={locale} hreflangs={hreflangs} {...seoInformation} />
      <Wrapper>
        <NavBar showLanguages={false} className="article-menu" />
        <Layout className="article-layout">
          <Text5 as="p">{moment(date).locale(locale).format('LL')}</Text5>
          <Header title={headline} showTags={false} />
          <Body content={content} />
        </Layout>
        <ContextualCta />
        <Footer page="press/:last-breadcrumb" lastBreadcrumb={headline} />
      </Wrapper>
    </>
  );
};
export default withI18next()(PressRelease);

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(eyebrow)|(breadcrumbs)|(contextual-cta)|(seo)|(course-list)|(blog)|(banner)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;
