import styled from 'styled-components';
import config from '../../components/config';

export default styled.section`
  .article-layout {
    padding-top: 4.8rem;

    & > p {
      color: ${config.palette.darkBlueTransparent('0.8')};
      margin-bottom: 0.8rem;
    }
    @media (max-width: ${config.resolutions.mobileL}) {
      padding-top: 0;
    }
  }

  .article-menu {
    padding-top: 3.2rem;

    a,
    div {
      color: ${config.palette.darkBlue};
    }

    nav {
      > a {
        border: 0.1rem solid ${config.palette.darkBlue};
        border-radius: ${config.borderRadius};
      }

      svg path {
        fill: ${config.palette.darkBlue};
      }
    }
  }
`;
